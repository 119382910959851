import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NumbersIcon from '@mui/icons-material/Numbers';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PaletteIcon from '@mui/icons-material/Palette';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PercentIcon from '@mui/icons-material/Percent';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FlagIcon from '@mui/icons-material/Flag';

import { load, load_component } from '../../main/App';

import AbstractHandler from '../../main/AbstractHandler';
import { AbstractMenu } from '../../main/AbstractMenu';



class SettingLoader extends AbstractHandler {
    constructor(props) {
        super('/setting.basic.php', 'get');
        this.reference = props.reference;
        this.reference.settingLoader = this;
    }

    handle(result, info) {
        if (result !== 0) return;
        this.reference.setting.value = info;
        this.reference.setting.openmain();
    }
}



class Menu extends AbstractMenu {

    constructor() {
        super([
            {
                title: window.lan._menu.title1,
                items: [
                    { name: window.lan._menu.information, icon: (<HomeIcon fontSize="small" />), fun: () => { load('/mercury'); } },
                    {},
                    { name: window.lan._menu.setting, icon: (<SettingsIcon fontSize="small" />), fun: () => { this.settingLoader.submit(); } },
                    { name: window.lan._menu.feature, icon: (<NumbersIcon fontSize="small" />), fun: () => { this.feature.openmain(); } },
                    { name: window.lan._menu.template, icon: (<PaletteIcon fontSize="small" />), fun: () => { load('/template'); } },
                    {},
                    { name: window.lan._menu.log, icon: (<FormatListBulletedIcon fontSize="small" />), fun: () => { load('/log'); } },
                    { name: window.lan._menu.group, icon: (<GroupIcon fontSize="small" />), fun: () => { load('/group'); } },
                    { name: window.lan._menu.administrator, icon: (<AccountCircleIcon fontSize="small" />), fun: () => { load('/administrator'); } },
                    { name: window.lan._menu.account, icon: (<ContactMailIcon fontSize="small" />), fun: () => { load('/account'); } },
                    {},
                    { name: window.lan._menu.static, icon: (<InsertDriveFileIcon fontSize="small" />), fun: () => { load('/static'); } },
                    { name: window.lan._menu.document, icon: (<AttachFileIcon fontSize="small" />), fun: () => { load('/document'); } }
                ]
            },
            {
                title: window.lan._menu.title2,
                items: [
                    { name: window.lan._menu.draft, icon: (<MailOutlineIcon fontSize="small" />), fun: () => { load('/draft'); } },
                    { name: window.lan._menu.profile, icon: (<InsertDriveFileIcon fontSize="small" />), fun: () => { load('/profile'); } },
                    {},
                    { name: window.lan._menu.userrecord, icon: (<FormatListBulletedIcon fontSize="small" />), fun: () => { load('/userrecord'); } },
                    {},
                    { name: window.lan._menu.voucher, icon: (<PercentIcon fontSize="small" />), fun: () => { load('/voucher'); } }
                ]
            },
            {
                title: window.lan._menu.title3,
                items: [
                    { name: window.lan._menu.service, icon: (<DashboardCustomizeIcon fontSize="small" />), fun: () => { load('/service'); } },
                    { name: window.lan._menu.level, icon: (<FlipToFrontIcon fontSize="small" />), fun: () => { load('/level'); } },
                    { name: window.lan._menu.catalog, icon: (<AccountTreeIcon fontSize="small" />), fun: () => { load('/catalog'); } },
                    {},
                    { name: window.lan._menu.flag, icon: (<DashboardCustomizeIcon fontSize="small" />), fun: () => { load('/flag'); } },
                    { name: window.lan._menu.permission, icon: (<FlagIcon fontSize="small" />), fun: () => { load('/permission'); } },
                    { name: window.lan._menu.coupon, icon: (<PercentIcon fontSize="small" />), fun: () => { load('/coupon'); } }
                ]
            }
        ]);
    }

    filter(item) {
        if (!window.config.admin.right) return false;
        if (!item.right) return true;
        return window.config.admin.right[item.right] && window.config.admin.right[item.right] > 0;
    }

    render() {
        const setting = load_component('setting', '', this);
        const feature = load_component('feature', '', this);
        return (<React.Fragment><SettingLoader reference={this} />{setting}{feature}{super.render()}</React.Fragment>);
    }
}



export default Menu;