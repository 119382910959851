import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { initialize_title, modify_title, split_path } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr, checkJSON } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/domain.basic', 'post', { 'siteID': props.siteID, 'url': '' }, props.reference, 'add_diag', window.lan.domain.add, window.lan.domain.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="url" label={window.lan.domain.add_url} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'url') return value.length < 1 || value.length > 255 ? window.lan.domain.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/domain.basic', 'put', {}, props.reference, 'edit_diag', window.lan.domain.edit, window.lan.domain.edit_submit, 70);
    }

    options() {
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.domain.edit_name} fullwidth />
                <TextInput form={this} id="url" label={window.lan.domain.edit_url} fullwidth />
                <Box sx={{ width: '100%' }}></Box>
            </Stack>),
            (<TextInput form={this} id="configuration" label={window.lan.domain.edit_configuration} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="html" label={window.lan.domain.edit_html} fullwidth multiline rows={4} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length > 31 ? window.lan.domain.err[1] : '';
        if (id === 'url') return value.length < 1 || value.length > 255 ? window.lan.domain.err[0] : '';
        if (id === 'configuration') return value.length > 4095 || !checkJSON(value) ? window.lan.domain.err[2] : '';
        if (id === 'html') return value.length > 4095 ? window.lan.domain.err[3] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/domain.basic?siteID=' + props.siteID, 'delete', {}, props.reference, 'del_diag', window.lan.domain.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.domain.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/domain.basic/copy', 'post', {}, props.reference, 'copy_diag');
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.domain.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.domain.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'domainID': props.row.ID }; props.reference.copy_diag.submit(); } });
        items.push({ name: window.lan.domain.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Domain extends AbstractTable {

    constructor(props) {
        const [base] = split_path(props.path);
        const siteID = parseInt(base);
        super('/feature/domain.basic?siteID=' + siteID,
            [
                { sortindex: 0, label: window.lan.domain.id },
                { sortindex: 2, label: window.lan.domain.name },
                { sortindex: 1, label: window.lan.domain.url },
                { sortindex: 2, label: window.lan.domain.insert_time },
                { sortindex: 3, label: window.lan.domain.update_time }
            ],
            window.lan.domain.infobox, 'Domain', '', '0$0', [],
            [
                window.lan.domain.id,
                window.lan.domain.url,
                window.lan.domain.name,
                window.lan.domain.insert_time,
                window.lan.domain.update_time
            ], true, false);
        this.siteID = siteID;
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.domain.title);
        return this.pdraw([{ info: window.lan.site.title }], (<React.Fragment><Add reference={this} siteID={this.siteID} /><Edit reference={this} /><Copy reference={this} /><Del reference={this} siteID={this.siteID} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return (<Typography>{row.name}</Typography>);
        if (cellindex === 3) return <Link href={row.url} underline="none" target="_blank">{row.url}</Link>;
        if (cellindex === 4) return getTimeStr(row.insertTime);
        if (cellindex === 5) return getTimeStr(row.updateTime);
    }

    drawToolbarLeft() {
        return (<React.Fragment>
            <Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.domain.del}</Button>
        </React.Fragment>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.domain.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_domain = function (info) {
    return window.lan.loginfo.add_domain.replace('%A%', info.id).replace('%B%', info.site_id).replace('%C%', info.url);
}

window.logfun.remove_domains = function (info) {
    return window.lan.loginfo.remove_domains.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_domain = function (info) {
    return window.lan.loginfo.edit_domain.replace('%A%', info.id).replace('%B%', info.url);
}

window.logfun.copy_domain = function (info) {
    return window.lan.loginfo.copy_domain.replace('%A%', info.domain_id).replace('%B%', info.id);
}



export default Domain;