import * as React from 'react';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MoveDownIcon from '@mui/icons-material/MoveDown';

import { initialize_title, modify_title, split_path } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/page.basic', 'post', { 'staticID': props.staticID, 'title': '' }, props.reference, 'add_diag', window.lan.page.add, window.lan.page.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="label" label={window.lan.page.add_label} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'label') return value.length > 63 ? window.lan.page.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/page.basic', 'put', {}, props.reference, 'edit_diag', window.lan.page.edit, window.lan.page.edit_submit, 70);
    }

    options() {
        return [
            (<TextInput form={this} id="label" label={window.lan.page.edit_label} fullwidth />),
            (<TextInput form={this} id="content" label={window.lan.page.edit_content} fullwidth multiline rows={8} />)
        ];
    }

    validate(id, value) {
        if (id === 'label') return value.length > 63 ? window.lan.page.err[0] : '';
        if (id === 'content') return value.length > 65535 ? window.lan.page.err[1] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/page.basic?staticID=' + props.staticID, 'delete', {}, props.reference, 'del_diag', window.lan.page.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.page.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/page.basic/copy', 'post', {}, props.reference, 'copy_diag');
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Move extends AbstractDialog {
    constructor(props) {
        super('', 'put', {}, props.reference, 'move_diag');
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.swap(info.id1, info.id2);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        if (!props.reference.dm.isFirst(props.row.ID)) items.push({ name: window.lan.page.moveup, icon: (<MoveUpIcon fontSize="small" />), fun: () => { props.reference.move_diag.value = { 'ID': props.row.ID }; props.reference.move_diag.submit('/feature/page.basic/up'); } });
        if (!props.reference.dm.isLast(props.row.ID)) items.push({ name: window.lan.page.movedown, icon: (<MoveDownIcon fontSize="small" />), fun: () => { props.reference.move_diag.value = { 'ID': props.row.ID }; props.reference.move_diag.submit('/feature/page.basic/down'); } });
        items.push({});
        items.push({ name: window.lan.page.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'pageID': props.row.ID }; props.reference.copy_diag.submit(); } });
        items.push({ name: window.lan.page.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.page.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Page extends AbstractTable {

    constructor(props) {
        const [base] = split_path(props.path);
        const staticID = parseInt(base);
        super('/feature/page.basic?staticID=' + staticID,
            [
                { sortindex: -1, label: window.lan.page.id },
                { sortindex: -1, label: window.lan.page.label, style: { width: '80%' } }
            ],
            window.lan.page.infobox, 'Page', '', '0$0', [], [], true, false);
        this.staticID = staticID;
    }

    draw() {
        this.staticName = '';
        if (window.config.buffer) window.config.buffer['static/static'].forEach((item) => { if (item.id === this.staticID) this.staticName = item.name; });

        initialize_title();
        modify_title(2, window.lan.page.title + ' # ' + this.staticName);
        return this.pdraw([{ link: '/static', info: window.lan.static.title }, { info: window.lan.page.title + ' # ' + this.staticName }], (<React.Fragment><Add reference={this} staticID={this.staticID} /><Edit reference={this} /><Del reference={this} staticID={this.staticID} /><Copy reference={this} /><Move reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.label;
    }

    drawEmptyCell() {
        return window.lan.page.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.page.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.page.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_page = function (info) {
    return window.lan.loginfo.add_page.replace('%A%', info.static_id).replace('%B%', info.id).replace('%C%', info.label);
}

window.logfun.remove_pages = function (info) {
    return window.lan.loginfo.remove_pages.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_page = function (info) {
    return window.lan.loginfo.edit_page.replace('%A%', info.id).replace('%B%', info.label);
}

window.logfun.copy_page = function (info) {
    return window.lan.loginfo.copy_page.replace('%A%', info.page_id).replace('%B%', info.id);
}



export default Page;