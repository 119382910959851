import * as React from 'react';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';

import { initialize_title, modify_title, load } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/support.basic', 'post', { 'userID': 0 }, props.reference, 'add_diag', window.lan.support.add, window.lan.support.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="userID" label={window.lan.support.add_user} fullwidth />)
        ];
    }

    validate(id, value) {
        /*
        if (id === 'index') {
            let isOK = true;
            window.config.buffer['static/static'].forEach((item) => { if (item.index.toLowerCase() === value.toLowerCase()) isOK = false; });
            return value.length < 1 || value.length > 31 || !isOK ? window.lan.static.err[0] : '';
        }
        if (id === 'name') return value.length < 1 || value.length > 255 ? window.lan.static.err[1] : '';
        */
        return '';
    }

    result(result, info) {
        if (result === 9) {
            this.validity['index'] = window.lan.support.err[0];
            this.openmain();
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/support.basic', 'delete', {}, props.reference, 'del_diag', window.lan.support.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.support.del_tip];
    }

    check(IDs) {
        /*const arr = [];
        for (const info of window.config.buffer['static/static']) {
            if (IDs.includes(info.id)) arr.push(info.index);
        }
        for (const info of window.config.buffer['static/static']) {
            if (info.parentIndex !== '' && !IDs.includes(info.id) && arr.includes(info.parentIndex)) {
                this.setState({ mode: 2, information: window.lan.static.err[3] });
                return false;
            }
        }*/
        return true;
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        //items.push({ name: window.lan.static.edit_page, icon: (<VerticalSplitIcon fontSize="small" />), fun: () => { load('/page/' + props.row.ID); } });
        //items.push({});
        //items.push({ name: window.lan.static.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'staticID': props.row.ID }; props.reference.copy_diag.openmain(); } });
        //items.push({ name: window.lan.static.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        //items.push({ name: window.lan.static.del, icon: (<ClearIcon fontSize="small" />), fun: () => { if (!props.reference.del_diag.check([props.row.ID])) return; props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Support extends AbstractTable {

    constructor() {
        super('/feature/support.basic',
            [
                { sortindex: 0, label: window.lan.support.id },
                { sortindex: 1, label: window.lan.support.state },
                { sortindex: -1, label: window.lan.support.user, style: { width: '45%' } },
                { sortindex: 2, label: window.lan.support.insert_time },
                { sortindex: 3, label: window.lan.support.update_time }
            ],
            window.lan.support.infobox, 'Support', '', '0$0', [],
            [
                window.lan.support.id,
                window.lan.support.state,
                window.lan.support.insert_time,
                window.lan.support.update_time
            ], true, false);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.support.title);
        return this.pdraw([{ info: window.lan.support.title }], (<React.Fragment><Add reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return '';//row.state > 0 ? (closed) : "";
        if (cellindex === 3) return row.email;
        if (cellindex === 4) return getTimeStr(row.insertTime);
        if (cellindex === 5) return getTimeStr(row.updateTime);
    }

    drawEmptyCell() {
        return window.lan.support.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0 || !this.del_diag.check(this.state.selected)) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.support.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.support.add}</Button>);
    }
}
    /*
440
330

	adm.write('<tr class="table-warning"><td>' + adm.getCheckAll('main') + '</td>' + adm.getFrameBar(eright ? ('<a class="btn btn-outline-secondary btn-sm" href="javascript:if(adm.getAll(\'main\', \'\') == \'\') adm.alert(\'alert\', lan.sup.del, lan.gen.err_empty); else adm.confirm(\'confirm\', lan.sup.del, lan.sup.del_tip, \'support.php\', \'action=del&IDList=\' + adm.getAll(\'main\', \',\'));">' + lan.sup.del + '</a> ') : '', 7, '') + adm.getFrameBar('<a class="btn btn-outline-dark btn-sm" href="javascript:sup.prepareAdd1();void(0);">' + lan.sup.addu + '</a> <a class="btn btn-outline-dark btn-sm" href="javascript:sup.prepareAdd2(1);void(0);">' + lan.sup.adds + '</a>&nbsp;&nbsp;', 1, 'right') + '</tr>');

	adm.write('<datalist id="userList"></datalist><template id="userTemplate"></template><datalist id="shopList"></datalist><template id="shopTemplate"></template>');

	adm.write('<tr class="table-warning"><td>' + adm.getCheckAll('main') + '</td>' + adm.getFrameBar(eright ? ('<a class="btn btn-outline-secondary btn-sm" href="javascript:if(adm.getAll(\'main\', \'\') == \'\') adm.alert(\'alert\', lan.sup.del, lan.gen.err_empty); else adm.confirm(\'confirm\', lan.sup.del, lan.sup.del_tip, \'support.php\', \'action=del&IDList=\' + adm.getAll(\'main\', \',\'));">' + lan.sup.del + '</a> ') : '', 4, '') + adm.getFrameBar('<a class="btn btn-outline-dark btn-sm" href="javascript:sup.prepareAdd1();void(0);">' + lan.sup.addu + '</a> <a class="btn btn-outline-dark btn-sm" href="javascript:sup.prepareAdd2(1);void(0);">' + lan.sup.adds + '</a>&nbsp;&nbsp;', 1, 'right') + '</tr>');


var menu = adm.getMenu(sup.menu(listID, ID, userID, shopID, state, insertTime, updateTime, email, name));

sup.menu = function(listID, ID, userID, shopID, state, insertTime, updateTime, email, name)
{
	var righta = adm.checkRight(2, 514);
	var rightb = adm.checkRight(4, 36);
	var eright = adm.checkRight(4, 16384);

	var arr = new Array();
	if(righta && userID > 0) arr.push(['javascript:user.searchQI = \'0$' + userID + '$' + userID + '\'; user.sortQI = \'\'; adm.post(\'user.php\', \'searchQI=0$' + userID + '$' + userID + '\');', 'admin/icon00.gif', lan.sup.user_tip]);
	if(rightb && shopID > 0) arr.push(['javascript:shop.searchQI = \'0$' + shopID + '$' + shopID + '\'; shop.sortQI = \'\'; adm.post(\'shop.php\', \'searchQI=0$' + shopID + '$' + shopID + '\');', 'admin/icon00.gif', userID == 0 ? lan.sup.shop_tip : lan.sup.shopc_tip]);
	arr.push([]);
	arr.push(['javascript:msg.clear();adm.get(\'message.php\', \'coreID=' + ID + '\');void(0);', 'support.l0/icon05.gif', lan.sup.edit_message]);
	arr.push([]);
	if(userID > 0 && state > 0) arr.push(['javascript:sup.prepareRelay(' + ID + ', ' + shopID + ');void(0);', 'support.l0/icon02.gif', lan.sup.relay]);
	if(state > 0) arr.push(['javascript:adm.confirm(\'confirm\', lan.sup.close, lan.sup.close_tip, \'support.php\', \'action=close&ID=' + ID + '\');void(0);', 'support.l0/icon03.gif', lan.sup.close]);
	if(eright) arr.push(['javascript:adm.confirm(\'confirm\', lan.sup.del, lan.sup.del_tip, \'support.php\', \'action=del&IDList=' + ID + '\');void(0);', 'support.l0/icon01.gif', lan.sup.del]);
	return arr;
}

sup.result = function(id, info)
{
	switch(id)
	{
		case 0:
			$('#main').modal('show');
			document.getElementById('userID').setCustomValidity(lan.sup.err[7]);
			break;
		case 1:
		case 4:
			$('#main').modal('show');
			document.getElementById('shopID').setCustomValidity(lan.sup.err[8]);
			break;
		case 2:
			adm.suspend();
			adm.addbuffer(sup.buf, info.toString());
			adm.gopage(sup.buf, sup.buf.page - 1, 'support.php');
			break;
		case 3:
			adm.suspend();
			adm.delbuffer(sup.buf, info);
			adm.gopage(sup.buf, sup.buf.page - 1, 'support.php');
			break;
		case 5:
		case 6:
			adm.suspend();
			adm.updbuffer(sup.buf, info.toString());
			adm.gopage(sup.buf, sup.buf.page - 1, 'support.php');
			break;
	}
}

sup.searchFun = function()
{
	return [
		[0, lan.sup.id, lan.sup.err[0], lan.sup.err[1], lan.sup.err[2]],
		[6, lan.sup.user, lan.sup.err[3]],
		[6, lan.sup.shop_search, lan.sup.err[4]],
		[3, lan.sup.type, sup.getType],
		[3, lan.sup.state, sup.getState],
		[5, lan.sup.insert_time, 1, lan.sup.err[5]],
		[5, lan.sup.update_time, 1, lan.sup.err[6]]
	];
}

sup.add = function(type)
{
	if(type == 0) sup.uhandler = null;
	sup.shandler = null;
	if(type == 0) sup.userlist();
	sup.shoplist();
	adm.showForm('main', '', type == 0 ? lan.sup.addu : lan.sup.adds, '<form id="mainform" class="was-validated">' + (type == 0 ? ('<div class="form-row">' + adm.getOption(lan.sup.add_user, '', '<input type="text" class="form-control" id="userID" name="userID" list="userList" placeholder="' + lan.sup.add_user_tip + '" oninput="if(sup.uhandler != null) clearTimeout(sup.uhandler); sup.uhandler = setTimeout(function(){adm.datalist(\'userTemplate\', \'userList\', \'userID\', 8);}, 500);" value="' + adm.findSearch(1, 0, sup.searchQI) + '" autocomplete="off"/>', lan.sup.err[7]) + '</div>') : '') + '<div class="form-row">' + adm.getOption(type == 0 ? lan.sup.add_shop_curr : lan.sup.add_shop, '', '<input type="text" class="form-control" id="shopID" name="shopID" list="shopList" placeholder="' + lan.sup.add_shop_tip + '" oninput="if(sup.shandler != null) clearTimeout(sup.shandler); sup.shandler = setTimeout(function(){adm.datalist(\'shopTemplate\', \'shopList\', \'shopID\', 8);}, 500);" value="' + adm.findSearch(2, 0, sup.searchQI) + '" autocomplete="off"/>', lan.sup.err[8]) + '</div>' + (type == 0 ? ('<div class="form-row">' + adm.getOption('', '', '<div class="form-check"><input class="form-check-input" type="checkbox" value="" id="noShop" onchange="document.getElementById(\'shopID\').value = \'\';document.getElementById(\'shopID\').disabled = this.checked;"><label class="form-check-label" for="noShop">' + lan.sup.add_no_shop + '</label></div>', '') + '</div>') : '') + '</form>', lan.sup.add_submit, sup.addSubmit.bind(null, type));
}

sup.userlist = function()
{
	if(sup.userinit) return;
	sup.userinit = true;
	var userStr = new Array();
	for(var i = buf.user.length - 1; i > 0; i--) userStr.push('<option value="' + buf.user[i][0] + '">' + buf.user[i][0] + ' # ' + buf.user[i][1] + '</option>');
	document.getElementById('userTemplate').innerHTML = userStr.join('');
	adm.datalist('userTemplate', 'userList', 'userID', 8);
}

sup.shoplist = function()
{
	if(sup.shopinit) return;
	sup.shopinit = true;
	var shopStr = new Array();
	for(var i = buf.shop.length - 1; i > 0; i--) shopStr.push('<option value="' + buf.shop[i][0] + '">' + buf.shop[i][0] + ' # ' + buf.shop[i][1] + ' # ' + buf.shop[i][2] + '</option>');
	document.getElementById('shopTemplate').innerHTML = shopStr.join('');
	adm.datalist('shopTemplate', 'shopList', 'shopID', 8);
}

sup.prepareAdd1 = function()
{
	if(buf.hasOwnProperty('user'))
	{
		adm.get('userbuffer.l0.php', 'time=' + buf.user[0], true, sup.prepareAdd2.bind(null, 0));
	}
	else
	{
		buf.user = new Array();
		adm.get('userbuffer.l0.php', '', true, sup.prepareAdd1);
	}
}

sup.prepareAdd2 = function(type)
{
	if(buf.hasOwnProperty('shop'))
	{
		adm.get('shopbuffer.l0.php', 'time=' + buf.shop[0], true, sup.add.bind(null, type));
	}
	else
	{
		buf.shop = new Array();
		adm.get('shopbuffer.l0.php', '', true, sup.prepareAdd2.bind(null, type));
	}
}

sup.addSubmit = function(type)
{
	var form = document.getElementById('mainform');
	var userID = document.getElementById('userID');
	var shopID = document.getElementById('shopID');
	var noShop = document.getElementById('noShop');
	if(type == 0) var userIDv = parseInt(userID.value);
	var shopIDv = parseInt(shopID.value);
	if(type == 0) userID.setCustomValidity(isNaN(userIDv) || userIDv < 1 ? lan.sup.err[7] : '');
	shopID.setCustomValidity(isNaN(shopIDv) || shopIDv < 1 ? lan.sup.err[8] : '');
	if(type == 0 && noShop.checked) shopID.setCustomValidity('');
	return form.checkValidity() ? ['support.php?action=add', $('form#mainform').serialize()] : null;
}

sup.relay = function(ID, shopID)
{
	sup.shandler = null;
	sup.shoplist();
	adm.showForm('main', '', lan.sup.relay, '<form id="mainform" class="was-validated"><div class="form-row">' + adm.getOption(lan.sup.relay_shop, '', '<input type="text" class="form-control" id="shopID" name="shopID" list="shopList" placeholder="' + lan.sup.relay_shop_tip + '" oninput="if(sup.shandler != null) clearTimeout(sup.shandler); sup.shandler = setTimeout(function(){adm.datalist(\'shopTemplate\', \'shopList\', \'shopID\', 8);}, 500);" value="" autocomplete="off"' + (shopID == 0 ? ' disabled' : '') + '/>', lan.sup.err[8]) + '</div><div class="form-row">' + adm.getOption('', '', '<div class="form-check"><input class="form-check-input" type="checkbox" value="" id="noShop" onchange="document.getElementById(\'shopID\').value = \'\';document.getElementById(\'shopID\').disabled = this.checked;"' + (shopID == 0 ? ' checked' : '') + '><label class="form-check-label" for="noShop">' + lan.sup.relay_no_shop + '</label></div>', '') + '</div><input type="hidden" name="ID" value="' + ID + '"/><input type="hidden" id="oshopID" value="' + shopID + '"/></form>', lan.sup.relay_submit, sup.relaySubmit);
}

sup.prepareRelay = function(ID, shopID)
{
	if(buf.hasOwnProperty('shop'))
	{
		adm.get('shopbuffer.l0.php', 'time=' + buf.shop[0], true, sup.relay.bind(null, ID, shopID));
	}
	else
	{
		buf.shop = new Array();
		adm.get('shopbuffer.l0.php', '', true, sup.prepareRelay.bind(null, ID, shopID));
	}
}

sup.relaySubmit = function()
{
	var form = document.getElementById('mainform');
	var shopID = document.getElementById('shopID');
	var oshopID = document.getElementById('oshopID');
	var noShop = document.getElementById('noShop');
	var shopIDv = parseInt(shopID.value);
	var oshopIDv = parseInt(oshopID.value);
	shopID.setCustomValidity(isNaN(shopIDv) || shopIDv < 1 || shopIDv == oshopIDv ? lan.sup.err[8] : '');
	if(noShop.checked && oshopIDv > 0) shopID.setCustomValidity('');
	return form.checkValidity() ? ['support.php?action=relay', $('form#mainform').serialize()] : null;
}

sup.getType = function(type)
{
	switch(type)
	{
		case 0: return lan.sup.type_0;
		case 1: return lan.sup.type_1;
	}
	return '';
}

sup.getState = function(state)
{
	switch(state)
	{
		case 0: return lan.sup.state_0;
		case 1: return lan.sup.state_1;
	}
	return '';
}
    */


/*
	sup.searchArr = [[0, 0, lan.sup.id, '%A%$%A%'], [1, 0, lan.sup.user_search, '%A%$%A%'], [2, 0, lan.sup.shop_search, '%A%$%A%']];
	var statebuf = [{id:0, title:lan.sup.state_0}, {id:1, title:lan.sup.state_1}];
	var searchBox = adm.searchex([adm.searchexMain('support.php', 'sup.searchQI', 'sup.sortQI', 'sup.searchArr'), adm.searchexSel('support.php', 'sup.searchQI', 'sup.sortQI', lan.sup.state, lan.sup.all, 4, 0, statebuf, 'id', 'title')]);
	adm.navi('<a href="javascript:adm.get(\'information.php\');">' + lan.gen.name + '</a> &gt; ' + lan.sup.title, searchBox);

	adm.write('<div class="container-fluid main-content"><table class="table table-borderless table-hover"><thead style="border-bottom:1px solid #E0E0E0"><th style="width:1%"></th><th style="width:4%"></th>' + adm.titleSort('support.php', lan.sup.id, 'sup.searchQI', 'sup.sortQI', 0, '10%') + adm.titleSimple(lan.sup.starter) + adm.titleSimple(lan.sup.shop) + adm.titleSort('support.php', lan.sup.update_time, 'sup.searchQI', 'sup.sortQI', 3) + '</thead><tbody>');
}



lan.sup.addu = "添加用户工单";
lan.sup.adds = "添加商铺工单";
lan.sup.edit_message = "查看工单";
lan.sup.relay = "移交工单";
lan.sup.close = "关闭工单";
lan.sup.close_tip = "关闭工单的操作无法被恢复，您确定要继续吗？";
lan.sup.del = "删除工单";
lan.sup.del_tip = "删除工单的操作无法被恢复，您确定要继续吗？";
lan.sup.background = "管理后台";
lan.sup.user_tip = "查看发起用户";
lan.sup.shop_tip = "查看发起商铺";
lan.sup.shopc_tip = "查看当前商铺";

lan.sup.type_0 = "用户工单";
lan.sup.type_1 = "商铺工单";
lan.sup.state_0 = "已关闭";
lan.sup.state_1 = "待处理";

lan.sup.add_user = "发起用户编号";
lan.sup.add_shop = "发起商铺编号";
lan.sup.add_shop_curr = "当前商铺编号";
lan.sup.add_user_tip = "用户编号支持联想输入，您可以输入注册邮箱中的关键字搜寻用户。";
lan.sup.add_shop_tip = "商铺编号支持联想输入，您可以输入商铺名称或者注册邮箱中的关键字搜寻商铺编号。";
lan.sup.add_no_shop = "管理后台";
lan.sup.add_submit = "添加";
lan.sup.relay_shop = "商铺编号";
lan.sup.relay_shop_tip = "商铺编号支持联想输入，您可以输入商铺名称或者注册邮箱中的关键字搜寻商铺编号。";
lan.sup.relay_no_shop = "管理后台";
lan.sup.relay_submit = "移交";

lan.sup.err = new Array();
lan.sup.err[0] = "您填写的起始工单编号无效！";
lan.sup.err[1] = "您填写的截止工单编号无效！";
lan.sup.err[2] = "您填写的工单编号范围无效！";
lan.sup.err[3] = "您填写的用户编号无效！";
lan.sup.err[4] = "您填写的商铺编号无效！";
lan.sup.err[5] = "您填写的发起时间无效！";
lan.sup.err[6] = "您填写的最后更新时间无效！";
lan.sup.err[7] = "您填写的用户编号无效！";
lan.sup.err[8] = "您填写的商铺编号无效！";

lan.sup.nstate_1 = "等待商铺处理";
lan.sup.nstate_2 = "等待后台处理";
lan.sup.all = "所有工单";
lan.sup.user_search = "发起用户编号";
lan.sup.shop_search = "发起 / 当前商铺编号";
    */





if (!window.logfun) window.logfun = {};

window.logfun.add_support = function (info) {
    return window.lan.loginfo.add_support.replace('%A%', info.id).replace('%B%', info.userID);
}

window.logfun.remove_support = function (info) {
    return window.lan.loginfo.remove_support.replace('%A%', info.id);
}



export default Support;